<template>
  <v-row no-gutters justify="center">

    <v-col cols="12" sm="10" md="9">
      <home_setup/>
    </v-col>

    <v-col cols="12" sm="10" md="9">
      <rosreestr_setup/>
    </v-col>

  </v-row>
</template>

<script>

export default {
  components: {
    home_setup: () => import("@/site/modules/server/setup/home"),
    rosreestr_setup: () => import("@/site/modules/server/setup/rosreestr"),
  },
  data: () => ({
    // server_data: null,
  }),
}
</script>
